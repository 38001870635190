.cg-wrap {
  background-image: url('/images/board.png');
  background-size: cover;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.cg-wrap-wrapper {
  padding: 15px;
  background-image: url('/images/board-background.png');
}
